<template lang="html">
  <section class="profile categorias documentos column-start">
    <router-link :to="{ name: 'home'}" class="volver">Volver</router-link>
    <h2 class="subtitulo-documentos row-center">
      <span></span>Mis datos<span></span>
    </h2>
    <Form
      :validation-schema="schema"
      @submit="onSubmit"
      v-slot="{ errors }"
      class="formulario-perfil row-between aifs"
    >
      <div class="left">
        <div id="nombre" class="contenedor-input">
          <label>Nombre</label>
          <Field
            name="name"
            class="input-styled"
            placeholder="Nombre"
            :class="{ error: errors.name }"
            v-model="user.name"
          />
          <span class="mss-error">{{ errors.name }}</span>
        </div>

        <div id="apellidos" class="contenedor-input">
          <label>Primer Apellido</label>
          <Field
            name="surname"
            class="input-styled"
            placeholder="Apellido"
            :class="{ error: errors.surname }"
            v-model="user.surname"
          />
          <span class="mss-error">{{ errors.surname }}</span>
        </div>

        <div id="apellidos" class="contenedor-input">
          <label>Segundo Apellido</label>
          <Field
            name="surname_2"
            class="input-styled"
            placeholder="Apellido"
            :class="{ error: errors.surname_2 }"
            v-model="user.surname_2"
          />

          <span class="mss-error">{{ errors.surname_2 }}</span>
        </div>

        <div id="email" class="contenedor-input">
          <label>Email</label>
          <Field
            name="email"
            class="input-styled"
            placeholder="Email"
            :class="{ error: errors.email }"
            v-model="user.email"
          />
          <span class="mss-error">{{ errors.email }}</span>
        </div>

        <div id="telefono" class="contenedor-input">
          <label>Teléfono</label>
          <Field
            name="phone"
            class="input-styled"
            placeholder="Teléfono"
            :class="{ error: errors.phone }"
            v-model="user.phone"
          />
          <span class="mss-error">{{ errors.phone }}</span>
        </div>
        <div id="dir" class="contenedor-input">
          <label>Dirección</label>
          <Field
            name="street"
            class="input-styled"
            placeholder="Dirección"
            :class="{ error: errors.street }"
            v-model="user.street"
          />
          <span class="mss-error">{{ errors.street }}</span>
        </div>
        <div id="codpos" class="contenedor-input">
          <label>Código Postal</label>
          <Field
            name="cp"
            class="input-styled"
            placeholder="Código Postal"
            :class="{ error: errors.cp }"
            v-model="user.cp"
          />
          <span class="mss-error">{{ errors.cp }}</span>
        </div>
        <div id="poblacion" class="contenedor-input">
          <label>Población</label>
          <Field
            name="city"
            class="input-styled"
            placeholder="Población"
            :class="{ error: errors.city }"
            v-model="user.city"
          />
          <span class="mss-error">{{ errors.city }}</span>
        </div>
      </div>

      <div class="right">
        <div id="country" class="contenedor-input">
          <label>País</label>
          <Field
            name="country"
            class="input-styled"
            placeholder="País"
            :class="{ error: errors.country }"
            v-model="user.country"
          />
          <span class="mss-error">{{ errors.country }}</span>
        </div>
        <div id="province" class="contenedor-input">
          <label>Provincia</label>
          <Field
            name="province"
            as="select"
            class="select-styled"
            :class="{ error: errors.province }"
            v-model="user.province"
          >
            <option
              v-for="(option, key) in $tm('select_values.province')"
              :key="key"
              :value="key"
            >
              {{ $rt(option) }}
            </option>
          </Field>
          <span class="mss-error">{{ errors.province }}</span>
        </div>

        <div id="profesion" class="contenedor-input">
          <label>Profesión</label>
          <Field
            name="profesion"
            as="select"
            class="select-styled"
            :class="{ error: errors.profesion }"
            v-model="user.profesion"
          >
            <option
              v-for="(option, key) in $tm('select_values.profession')"
              :key="key"
              :value="key"
            >
              {{ $rt(option) }}
            </option>
          </Field>
          <span class="mss-error">{{ errors.profesion }}</span>
        </div>
        <div id="especialidad" class="contenedor-input">
          <label>Especialidad</label>
          <Field
            name="speciality"
            as="select"
            class="select-styled"
            :class="{ error: errors.speciality }"
            v-model="user.speciality"
          >
            <option
              v-for="(option, key) in $tm('select_values.speciality')"
              :key="key"
              :value="key"
            >
              {{ $rt(option) }}
            </option>
          </Field>
          <span class="mss-error">{{ errors.speciality }}</span>
        </div>
        <div class="column contenedor-pass contenedor-input">
          <label>Nueva Password</label>
          <Field
            type="password"
            name="password_new"
            class="input-styled"
            placeholder="Contraseña"
            :class="{ error: errors.password_new }"
            v-model="password.password_new"
          />
          <span class="mss-error">{{ errors.password_new }}</span>
          <a><i @click="toggleShowPassword" class="fa fa-eye"></i></a>
        </div>

        <div class="column contenedor-pass contenedor-input">
          <label>Repetir Password</label>
          <Field
            type="password"
            name="password_new_confirmation"
            class="input-styled"
            placeholder="Repite contraseña"
            :class="{ error: errors.password_new_confirmation }"
            v-model="password.password_new_confirmation"
          />
          <span class="mss-error">{{ errors.password_new_confirmation }}</span>
          <a><i @click="toggleShowPassword" class="fa fa-eye"></i></a>
        </div>
        <div class="checkbox-styled">
          <Field
            id="newsletter"
            name="newsletter"
            type="checkbox"
            v-model="user.newsletter"
            :value="true"
            :unchecked-value="false"
          />

          <label for="newsletter">
            Acepto recibir las novedades de Santiveri por e-mail. Puedo darme de
            baja cuando quiera.
          </label>
        </div>

        <div id="botones">
          <button class="boton-styled">Guardar</button>
        </div>
      </div>
    </Form>
  </section>
</template>

<script lang="js">
import { Form, Field, defineRule, configure, ErrorMessage } from "vee-validate";
import { mapGetters, mapActions, mapMutations} from 'vuex';
import router from '@/router';
import store from '@/store';
  export default  {
    name: 'profile',
    props: [],
    mounted () {
    },
     data () {
       const schema = {
        name: 'required',
        surname : 'required',
        surname_2 : 'required',
        password_new: 'min:8',
        password_new_confirmation :'confirmed:@password_new',
        email: 'required|email',
        phone: 'required',
        province : 'required',
        country  : 'required',
        city : 'required',
        cp : 'required',
        street : 'required',
        profesion : 'required',
        speciality : 'required',

      };
      return {
        schema,
        showPassword : false,
        password:{
          password_new:'',
          password_new_confirmation :'',
        }
      }
    },
    methods: {
      ...mapActions({
        modificateUser : 'auth/modificateUser'
      }),
      ...mapMutations({
       launchAlert: "alert/launchAlert",
       }),
      onSubmit(){
        if(this.password.password_new.lenght > 0 && this.password.password_new_confirmation.lenght > 0){
         this.user.password_new = this.password.password_new
         this.user.password_new_confirmation = this.password.password_new_confirmation
        }
        this.modificateUser(this.user).then(r => {
          if (r) {
              this.launchAlert('Se realizó con éxito.');
              this.$router.push({ name: 'profile'});
          }
        })
      },
      toggleShowPassword: function() {
        this.showPassword = !this.showPassword;
        let password = event.target.parentNode.parentNode.querySelector('input')
       if (this.showPassword == true) {
          password.type = "text"
          event.target.classList.add("fa-eye-slash")
          event.target.classList.remove("fa-eye")
       }else{
          password.type = "password"
          event.target.classList.remove("fa-eye-slash")
          event.target.classList.add("fa-eye")
       }
      }
    },
    computed: {
      ...mapGetters({
         originalUser : 'auth/getUser'
       }),
       user : function () {
         return Object.assign({}, this.originalUser)
       }

    },
    components: {
      Field,
      Form
    }
}
</script>

<style scoped lang="scss">
.mss-error {
  display: inline-block;
  text-align: left;
  width: 100%;
  max-width: 285px;
  color: #d32626;
}
.formulario-perfil .contenedor-input input {
  margin-bottom: 0;
}
</style>
